export const itens = [
  {
    id: '12',
    title: 'Sem anuidade',
    content: 'Não tem tarifa de manutenção e você paga apenas pelo que consome.',
  },
  {
    id: '13',
    title: 'Internacional',
    content: 'Aviso Viagem direto pelo app para você usar o cartão em milhões de estabelecimentos pelo mundo.',
  },
  {
    id: '14',
    title: 'Antecipe Parcelas',
    content: 'Desconto na antecipação, seja para liberar o limite ou não comprometer a renda por muitos meses.',
  },
];
