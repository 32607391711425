import React from 'react';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

import { itens } from './Itens';

function SectionNoTaxesVariant() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713550638-4-dobra-cartao-desk-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713550638-4-dobra-cartao-desk-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713550638-4-dobra-cartao-desk-1.jpg' },
  }]);

  return (
    <Box
      tag="section"
      backgroundColor="white"
      paddingVertical={{ xs: '12x', md: '20x', lg: '32x' }}
    >
      <Grid
        paddingLeft={{ xs: '8x', md: '16x' }}
        paddingRight={{ xs: '8x', md: '16x' }}
        rowGap={{ xs: '6x', md: '12x' }}
        alignItems={{ lg: 'center' }}
      >
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '7 / span 6' }}
        >
          <Image
            loading="lazy"
            srcSet={imageSrcSet}
            alt="Foto cartões nubank"
            Title="Cartão nubank"
          />
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 5' }}
          gridRow={{ lg: '1' }}
        >
          <Typography
            variant="heading2"
            tag="h2"
            color="black"
            colorVariant="light"
            marginBottom="4x"
          >
            Vantagens do Cartão do Nubank
          </Typography>
          {itens.map(item => (
            <Box>
              <Typography
                variant="heading4"
                tag="h4"
                color="primary.default"
                colorVariant="light"
                marginBottom="2x"
              >
                {item.title}
              </Typography>
              <Typography
                marginBottom="5x"
                variant="subtitle1"
                tag="p"
              >
                {item.content}
              </Typography>
            </Box>
          ))}

        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionNoTaxesVariant);
